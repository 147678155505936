
export default {
  name: 'Carousel',
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    controls: {
      type: Boolean,
      default: true
    },
    controlsPosition: {
      type: String,
      default: 'side',
      validator(value) {
        return ['top', 'bottom', 'side'].includes(value);
      }
    },
    center: {
      type: Boolean,
      default: false
    },
    countItems: {
      type: Number,
      default: 0
    },
    infinite: {
      type: Boolean,
      default: false
    },
    interval: {
      type: Number,
      default: 5000
    },
    itemToScroll: {
      type: String,
      default: ''
    },
    perPage: {
      type: Number,
      default: 4
    },
    iconButtonType: {
      type: String,
      default: 'secondary'
    },
    dots: {
      type: Boolean,
      default: false
    },
    dotsColor: {
      type: String,
      default: '#4E2096'
    },
    highlightActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      intervalId: null,
      isScrollingProgrammatically: false
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.countItems / this.perPage);
    },
    showControlTop() {
      return this.controls && this.controlsPosition === 'top';
    },
    showControlBottom() {
      return this.controls && this.controlsPosition === 'bottom';
    },
    showControlSide() {
      return this.controls && this.controlsPosition === 'side';
    }
  },
  watch: {
    itemToScroll() {
      this.scrollToItem(this.itemToScroll);
    }
  },
  mounted() {
    if (this.infinite) {
      this.setupInterval();
    }

    if (this.itemToScroll) {
      this.scrollToItem(this.itemToScroll);
    }
  },
  beforeDestroy() {
    if (this.infinite) {
      this.clearInterval();
    }
  },
  methods: {
    async moveToPage(page) {
      this.isScrollingProgrammatically = true;
      const { offsetWidth } = this.$refs.slider;

      if (page <= 0) {
        page = this.totalPages;
      } else if (page > this.totalPages) {
        page = 1;
      }

      await this.$refs.slider.scroll({
        left: (offsetWidth - 65) * (page - 1),
        behavior: 'smooth'
      });

      this.page = page;

      this.$emit('change', this.page);
    },

    async previous() {
      await this.moveToPage(this.page - 1);
    },

    async next() {
      await this.moveToPage(this.page + 1);
    },

    setupInterval() {
      this.setupInterval = setInterval(() => {
        this.next();
      }, this.interval);
    },

    clearInterval() {
      clearInterval(this.intervalId);
    },

    scrollToItem(itemId) {
      this.$nextTick(() => {
        const container = this.$refs.slider;
        const element = document.getElementById(itemId);

        if (element) {
          const containerWidth = container.offsetWidth;
          const elementLeft = element.offsetLeft;
          const elementWidth = element.offsetWidth;
          const scrollPosition =
            elementLeft - containerWidth / 2 + elementWidth / 2;

          container.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
          });
        }
      });
    },

    scrollToDots(page) {
      const container = this.$refs.slider;
      const content = this.$refs.sliderContent;

      const containerWidth = container.offsetWidth;

      const contentWidth = content.offsetWidth;

      const itemWidth = contentWidth / this.countItems;

      const scrollPosition =
        itemWidth * (page - 1) - (containerWidth / 2 - itemWidth / 2);

      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });

      this.page = page;

      if (this.highlightActive) {
        this.$emit('page-changed', page);
      }
    },

    updateActivePage() {
      if (this.isScrollingProgrammatically) return;

      clearTimeout(this.updatePageTimeout);
      this.updatePageTimeout = setTimeout(() => {
        const container = this.$refs.slider;
        const content = this.$refs.sliderContent;

        const contentWidth = content.offsetWidth;
        const containerWidth = container.offsetWidth;
        const scrollLeft = container.scrollLeft;
        const maxScroll = contentWidth - containerWidth;

        const threshold = containerWidth <= 768 ? 50 : 10;

        if (scrollLeft <= threshold) {
          this.page = 1;
          this.$emit('page-changed', 1);
          return;
        }

        if (Math.abs(scrollLeft - maxScroll) < threshold) {
          this.page = this.countItems;
          this.$emit('page-changed', this.countItems);
          return;
        }

        const itemWidth = contentWidth / this.countItems;
        const containerCenter = scrollLeft + containerWidth / 2;

        let closestPage = 1;
        let closestDistance = Infinity;

        for (let i = 0; i < this.countItems; i++) {
          const itemCenter = i * itemWidth + itemWidth / 2;
          const distance = Math.abs(containerCenter - itemCenter);

          if (distance < closestDistance) {
            closestDistance = distance;
            closestPage = i + 1;
          }
        }

        const scrollPosition =
          itemWidth * (closestPage - 1) - (containerWidth - itemWidth) / 2;

        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });

        this.page = closestPage;
        this.$emit('page-changed', closestPage);
      }, 100);
    },

    getDotStyle(index) {
      return {
        backgroundColor: this.page === index + 1 ? this.dotsColor : '#d9d3cc'
      };
    }
  }
};

import DogAndCatRed from '@/assets/images/illustrations-ds/dog-and-cat-red.svg';
import DogHouseOrange from '@/assets/images/illustrations-ds/dog-house-orange.svg';
import MoneyGreen from '@/assets/images/illustrations-ds/money-green.svg';
import ShoppingCartPurple from '@/assets/images/illustrations-ds/shopping-cart-purple.svg';

export const BASE_DISCOUNT_CLUB_CARDS = [
  {
    title: 'Até 25% de desconto em compras no site e app da Petlove',
    description:
      '25% OFF em acessórios, brinquedos e medicina e 15% OFF em alimentos, higiene e outras categorias',
    descriptionColor: '#17624C',
    img: '/illustrations-ds/money-green.svg',
    alt: 'Ícone de uma moeda de dinheiro laranja em um fundo redondo verde.',
    borderColor: '#49A971',
    tagColor: 'positive',
    svgComponent: MoneyGreen
  },
  {
    title: 'Frete grátis em compras no site e app da Petlove',
    description:
      'Frete grátis em compras, com entrega na modalidade padrão, sem valor mínimo',
    descriptionColor: '#4E2096',
    img: '/illustrations-ds/shopping-cart-purple.svg',
    alt: 'Ícone de um carrinho de compras laranja em um fundo redondo roxo.',
    borderColor: '#4E2096',
    tagColor: 'primary',
    svgComponent: ShoppingCartPurple
  },
  {
    title: '15% de desconto para aproveitar nas lojas físicas da Petlove',
    description:
      'Desconto em qualquer produto, de qualquer categoria, e no nosso serviço incrível de banho e tosa',
    descriptionColor: '#934814',
    img: '/illustrations-ds/dog-house-orange.svg',
    alt: 'Ícone de uma casa de cachorro branca com telhado e porta roxa em um fundo redondo laranja.',
    borderColor: '#FFA840',
    tagColor: 'alert',
    svgComponent: DogHouseOrange
  },
  {
    title: '15% de desconto em  nossos serviços de cuidado pet',
    description:
      'Deixe seu pet sem preocupações com heróis da Doghero para hospedagem, creche e pet sitter',
    descriptionColor: '#EA534A',
    img: '/illustrations-ds/dog-and-cat-red.svg',
    alt: 'Ícone de um cachorro laranja sentado ao lado de um gato branco em um fundo redondo vermelho"',
    borderColor: '#EA534A',
    tagColor: 'negative',
    svgComponent: DogAndCatRed
  }
];
